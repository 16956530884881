"use client";
import megaMenuImage1 from "@/assets/img/mega/mega_menu_1.png";
import DropdownPages from "./DropdownPages";
import NavItem from "./NavItem";
import { useHeaderContex } from "@/providers/HeaderContex";
import LanguageDropdown from "@/components/shared/LanguageDropDown/LanguageDropDown";
import { useTranslations } from "next-intl";
import { getNavbarElements } from "@/libs/sanity/config";
import { useCookies } from "next-client-cookies";
import { useEffect, useState } from "react";

const Navbar = () => {
  const { isOnepage } = useHeaderContex();
  const t = useTranslations("Navbar");
  const cookie = useCookies();
  const lang = cookie.get("lang") || "az";
  const [navbarelements, setNavbarElement] = useState([]);

  useEffect(() => {
    getNavbarElements(lang).then((response) => {
      setNavbarElement(response);
    });
  }, [lang]);

  const navItems = navbarelements?.map((element) => {
    const hasChildren = element.children && element.children.length > 0;

    return {
      name: element?.heading,
      icon: true,
      path: element?.url ? `/${element?.url?.current}` : "/",
      dropdown:
        hasChildren && !isOnepage ? (
          <DropdownPages
            dropdownContents={[
              {
                title: element?.heading,
                dropdownItems: element?.children
                  .filter((child) => child.heading !== element.heading)
                  .map((child) => ({
                    name: child?.heading,
                    path: `/${child.url}`,
                  })),
              },
            ]}
            dropdownThumbs={{
              img: megaMenuImage1,
              path: "#",
            }}
          />
        ) : null,
    };
  });

  return (
    <div className="headerarea__component">
      <div className="headerarea__main__menu">
        <nav>
          <ul>
            {navItems?.map((navItem, idx) => (
              <NavItem key={idx} item={navItem} />
            ))}
            <LanguageDropdown />
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
