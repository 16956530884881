import setLanguageAction from "@/actions/set-language-action";
import {useCookies} from "next-client-cookies";

const LanguageDropdown = () => {
const cookies=useCookies()
    const lang=cookies.get("lang")||"az"
    const handleLanguageChange = (event) => {
        setLanguageAction(event.target.value);
    };

    return (
        <select onChange={handleLanguageChange} defaultValue={lang} >
            <option value="az">AZ</option>
            <option value="en">EN</option>
            <option value="ru">RU</option>
        </select>
    );
};

export default LanguageDropdown;
