import { createClient } from "@sanity/client";

import imageUrlBuilder from "@sanity/image-url";

export const client = createClient({
  projectId: "fcic0eck",
  dataset: "production",
  useCdn: true,
  apiVersion: "2023-05-03",
  token:
    "skTKyTb7ixDJwI2Oy5r3U2dqrPpLIkKcHwk0RUqZ7HogSxvzRCQQNM93ggolWwQXfSchSrFxtFRmweNCsFrVoZoCVKNTygrIElMvn9OvgtoyqwQVTpkno8GjfR9wJObzdCPk1Lu4CgDYi487ja3Y9SE24SfdHkJSgoSXGBXSXEp8iMiC5ZNR",
});

const builder = imageUrlBuilder(client);

export function SanitImageUrl(source) {
  if (source?.asset?._ref?.endsWith(".svg")) {
    return source.asset.url;
  }
  return builder.image(source).auto("format").fit("max");
}
export async function getNavbarElements(lang = "az") {
  const navs = await client.fetch(
    `*[_type == "navbarsElements" && visible == true] | order(_updatedAt desc){
        "heading": heading[$lang],
          "url":url,
          "children": children[]->{
          "heading": heading[$lang],
          "url": url.current,
      }
    }`,
    { lang }
  );

  return navs || [];
}

export async function getHomeBanner(lang = "az") {
  const query = `
        *[_type == "homebanner" && visible == true]{
            "heading": heading[$lang],
            "description": description[$lang],
            "image": image
        }
    `;

  const posts = await client.fetch(query, { lang });

  return posts || [];
}

export async function getFaq(lang = "az") {
  const query = `
        *[_type == "faq" && visible == true]{
            "question": question[$lang],
            "answer": answer[$lang]
        }
    `;

  const faq = await client.fetch(query, { lang });

  return faq || [];
}

export async function getServices(lang = "az") {
  const services = await client.fetch(
    `*[_type == "services" && visible == true]{
        "heading": heading[$lang],
        "description": description[$lang],
        "slug": slug,
        "image": image,
        "content": content[$lang]
    }`,
    { lang }
  );

  return services || [];
}
export async function getServicesHeading(lang = "az") {
  const services = await client.fetch(
    `*[_type == "servicesheading" && visible == true]{
        "heading": heading[$lang],
        "description": description[$lang],
    }`,
    { lang }
  );

  return services || [];
}
export async function getHeaderTestimonials(lang = "az") {
  const services = await client.fetch(
    `*[_type == "headerTestimonials" && visible == true]{
        "heading": heading[$lang],
        "description": description[$lang],
    }`,
    { lang }
  );

  return services || [];
}

export async function getAboutServices(lang = "az") {
  const services = await client.fetch(
    `*[_type == "aboutservices" && visible == true]{
        "heading": heading[$lang],
        "description": description[$lang],
        "experience": experience,
        "image": image,
        "content": content[$lang]
        
    }`,
    { lang }
  );

  return services || [];
}
export async function getServiceBySlug(slug, lang = "az") {
  const query = `
        *[_type == "services" && slug.current == $slug][0]{
            "heading": heading[$lang],
            "description": description[$lang],
            "slug": slug,
            "image": image,
            "content": content[$lang]
        }
    `;
  const params = { slug, lang };

  const service = await client.fetch(query, params);

  return service || null;
}

export async function getProjects(lang = "az") {
  const projects = await client.fetch(
    `*[_type == "projects" && visible == true]{
        "heading": heading[$lang],
        "slug": slug,
        "image": image,
        "content": content[$lang]
    }`,
    { lang }
  );

  return projects || [];
}

export async function getProjectBySlug(slug, lang = "az") {
  const query = `
        *[_type == "projects" && slug.current == $slug][0]{
            "heading": heading[$lang],
            "slug": slug,
            "image": image,
            "content": content[$lang]
        }
    `;
  const params = { slug, lang };

  const project = await client.fetch(query, params);

  return project || null;
}

export async function getTestimonials(lang = "az") {
  const testimonials = await client.fetch(
    `*[_type == "testimonials" && visible == true]{
        "username": username,
        "heading": heading[$lang],
        "profession": profession
    }`,
    { lang }
  );

  return testimonials || [];
}

export async function getTeamsMembers(lang = "az") {
  const query = `
        *[_type == "teams" && visible == true]{
            "fullname": fullname[$lang],
            "slug": slug,
            "position": position[$lang],
            "email": email,
            "phone": phone,
            "image": image,
            "content": content[$lang],
            "linkedinurl": linkedinurl,
            "facebookurl": facebookurl,
            "twitterurl": twitterurl
        }
    `;

  const members = await client.fetch(query, { lang });

  return members || [];
}

export async function getTeamsMemmberBySlug(slug, lang = "az") {
  const query = `
        *[_type == "teams" && slug.current == $slug][0]{
            "fullname": fullname[$lang],
            "slug": slug,
            "position": position[$lang],
            "email": email,
            "phone": phone,
            "image": image,
            "content": content[$lang],
            "linkedinurl": linkedinurl,
            "facebookurl": facebookurl,
            "twitterurl": twitterurl
        }
    `;

  const params = { slug, lang };

  const member = await client.fetch(query, params);

  return member || null;
}

export async function getBlogs(page = 1, pageSize = 6, lang = "az") {
  const start = (page - 1) * pageSize;

  // Define the field selection based on the language
  const query = `{
        "blogs": *[_type == "blogs" && visible == true] | order(createdAt) [${start}...${start + pageSize}]{
            "heading": heading[$lang],
            "slug": slug,
            image,
            "content": content[$lang],
            createdAt,
            "description": description[$lang]
        },
        "total": count(*[_type == "blogs" && visible == true])
    }`;

  // Pass the language code as a parameter in the query
  const params = { lang };

  const result = await client.fetch(query, params);
  return result;
}

export async function getBlogBySlug(slug, lang = "az") {
  try {
    const query = `
            *[_type == "blogs" && slug.current == $slug][0]{
                "heading": heading[$lang],
                "slug": slug,
                image,
                "content": content[$lang],
                createdAt,
                tags,
                "description": description[$lang]
            }
        `;
    const params = { slug, lang };
    const blog = await client.fetch(query, params);
    return blog || null;
  } catch (error) {
    console.error("Error fetching blog by slug:", error);
    return null;
  }
}

export async function Getbreadcrumbarea() {
  const query = `*[_type == "breadcrumbarea" && visible==true]{totalproducts, projectcompleted, happyclient, teammember, createdat}`;

  const blog = await client.fetch(query);

  return blog || [];
}

export async function getBuisnessDevelopment(lang = "az") {
  const query = `*[_type == "buisnessdevelopment" && visible==true]{ 
           "heading": heading[$lang],
            "description": description[$lang],
            "image": image,
            "content": content[$lang]}`;

  const buisness = await client.fetch(query, { lang });

  return buisness || [];
}

export async function getHumanResurces(lang = "az") {
  const query = `*[_type == "humanresurces" && visible==true]{ 
           "heading": heading[$lang],
            "description": description[$lang],
            "image": image,
            "content": content[$lang]}`;

  const result = await client.fetch(query, { lang });

  return result || [];
}

export async function getLegalRegulations(lang = "az") {
  const query = `*[_type == "legal-regulation" && visible==true]{ 
           "heading": heading[$lang],
            "description": description[$lang],
            "image": image,
            "content": content[$lang]}`;

  const result = await client.fetch(query, { lang });

  return result || [];
}
export async function getTaxAndAudit(lang = "az") {
  const query = `*[_type == "fix-audit" && visible==true]{ 
           "heading": heading[$lang],
            "description": description[$lang],
            "image": image,
            "content": content[$lang]}`;

  const result = await client.fetch(query, { lang });

  return result || [];
}
export async function getDigitalTransform(lang = "az") {
  const query = `*[_type == "digital-transform" && visible==true]{ 
           "heading": heading[$lang],
            "description": description[$lang],
            "image": image,
            "content": content[$lang]}`;

  const result = await client.fetch(query, { lang });

  return result || [];
}
export async function getMergers(lang = "az") {
  const query = `*[_type == "mergers" && visible==true]{ 
           "heading": heading[$lang],
            "description": description[$lang],
            "image": image,
            "content": content[$lang]}`;

  const result = await client.fetch(query, { lang });

  return result || [];
}
export async function getPartners() {
  const query = `*[_type == "partners" && visible==true]{ 
            "image": image,
            "url": url }`;

  const result = await client.fetch(query);

  return result || [];
}
