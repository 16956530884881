import DropdownItem from "./DropdownItem";

const DropdownContent = ({ dropdownContent }) => {
  const { title, dropdownItems } = dropdownContent;

  return (
    <div className=" headerarea__mega__menu__single__wrap w-full">
      <ul className="headerarea__mega__menu__item">
        {dropdownItems?.map((item, idx) => (
          <DropdownItem key={idx} item={item} />
        ))}
      </ul>
    </div>
  );
};

export default DropdownContent;
