import Image from "next/image";
import Link from "next/link";
import logoImag2 from "../../../assets/img/Colored Drop shadow.png";
import logoImag1 from "../../../assets/img/colored (2).png";
import { useTranslations } from "next-intl";

const LogoFooter = ({ style }) => {
  const t = useTranslations("footer");
  return (
    <div
      className={`col-xl-4 ${!style ? "col-lg-6" : "col-lg-4"} col-md-6`}
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      <div
        className="footer__widget footer__left position-relative "
        // style={{ zIndex: 10 }}
      >
        <div className="footer__logo">
          {style === 2 ? (
            <>
              <Image
                width={60}
                height={60}
                sizes={"(max-width: 575px) 100px, 60px"}
                src={logoImag1}
                alt=""
                className="logo-image1"
              />
              <Image src={logoImag2} alt="" className="logo-image2" />
            </>
          ) : (
            <Image
              sizes={"(max-width: 575px) 100px, 60px"}
              width={60}
              height={60}
              src={logoImag1}
              alt=""
            />
          )}
        </div>
        <div className="footer__text">
          <p>{t("text")}</p>
        </div>
        <div className="footer__icon">
          <ul>
            <li>
              <Link href="https://www.facebook.com">
                <i className="icofont-facebook"></i>
              </Link>
            </li>
            <li>
              <Link href="https://x.com">
                <i className="icofont-twitter"></i>
              </Link>
            </li>
            <li>
              <Link href="https://www.skype.com">
                <i className="icofont-skype"></i>
              </Link>
            </li>
            <li>
              <Link href="https://www.linkedin.com">
                <i className="icofont-linkedin"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LogoFooter;
