import { useEffect, useState } from "react";
import { useHeaderContex } from "@/providers/HeaderContex";
import { useCookies } from "next-client-cookies";
import { getNavbarElements } from "@/libs/sanity/config";
import ItemMobile from "./ItemMobile";
import Link from "next/link";

const NavbarMobile = () => {
    const { isOnepage } = useHeaderContex();
    const cookie = useCookies();
    const lang = cookie.get("lang") || "az";
    const [mobileItems, setMobileItems] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(null); // Track open dropdown index

    useEffect(() => {
        getNavbarElements(lang).then((response) => {
            const items = response.map((element) => {
                const hasChildren = element.children && element.children.length > 0;

                return {
                    name: element?.heading,
                    path: element?.url ? `/${element?.url?.current}` : "/",
                    toggler: hasChildren,
                    dropdown: hasChildren
                        ? element.children.map((child) => ({
                            name: child?.heading,
                            path: `/${child.url}`,
                        }))
                        : null,
                };
            });
            setMobileItems(items);
        });
    }, [lang]);

    const handleToggle = (idx) => {
        setOpenDropdown((prev) => (prev === idx ? null : idx));
    };

    return (
        <nav className="offcanvas__menu">
            <ul className="offcanvas__menu_ul">
                {mobileItems?.map((item, idx) => (
                    <li key={idx} className={`offcanvas__menu_item ${item.toggler ? "has-children" : ""}`}>
                        <div className="offcanvas__menu_item_link">
                            <Link href={item.path}>{item.name}</Link>
                            {item.toggler && (
                                <button
                                    onClick={() => handleToggle(idx)}
                                    aria-expanded={openDropdown === idx}
                                    className="dropdown-toggler"
                                >
                                    {openDropdown === idx ? "-" : "+"}
                                </button>
                            )}
                        </div>

                        {/* Dropdown Items with Animation */}
                        <ul
                            className={`offcanvas__submenu ${openDropdown === idx ? "open" : ""}`}
                            style={{
                                maxHeight: openDropdown === idx ? "500px" : "0",
                                transition: "max-height 0.3s ease",
                            }}
                        >
                            {item.dropdown?.map((child, childIdx) => (
                                <li key={childIdx} className="offcanvas__submenu_item">
                                    <a href={child.path}>{child.name}</a>
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default NavbarMobile;
