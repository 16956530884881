import Image from "next/image";
import Link from "next/link";
import brandBadgeImage from "@/assets/img/footer/brand__badge.png";
import brandBadgeImage1 from "@/assets/img/herobaner/hero__badge_1.png";
import brandBadgeInnerImage from "@/assets/img/footer/brand__badge__inner.png";
const BrandBadge = ({ type, badge }) => {
  return (
    <div
      className={`bastun__brand__badge ${
        type === "footer" ? "bastun__brand__badge__footer" : ""
      } `}
    >

    </div>
  );
};

export default BrandBadge;
