import Image from "next/image";
import React from "react";
import DropdownContent from "./DropdownContent";
import Link from "next/link";

const DropdownPages = ({ dropdownContents, dropdownThumbs }) => {
  return (
    <div className="headerarea__submenu headerarea__mega__menu__wrapper">
      <div className="row">
        {dropdownContents?.map((dropdownContent, idx) => (
          <DropdownContent key={idx} dropdownContent={dropdownContent} />
        ))}

      </div>
    </div>
  );
};

export default DropdownPages;
